import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import Relation from '../images/services/rationalizaion.jpg'
import Network from '../images/services/networking.jpg'
import Support from '../images/services/support.jpg'
import Security from '../images/services/security.jpg'
import Management from '../images/services/management.jpg'
import Performance from '../images/services/performance.jpg'
import Backup from '../images/services/backup.jpg'
import Virtualization from '../images/services/virtualization.jpg' 
import Consulting from '../images/services/consulting.jpg'
import Cloud from '../images/services/cloud.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import Consulting1 from '../images/services/consulting.jpg'
import Implementation from '../images/services/implementation.jpeg'
import Applications from '../images/services/applications.jpg'
import { StaticImage } from "gatsby-plugin-image"

const SolutionsPage = () => {
    return (
        <Layout pageTitle="Features">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                        <div className="scroll-down-wrap">
                    <span>sentinel@pragicts.com</span>
                        <span>+94 11 70 4598 983</span>
                     
                    </div>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SMILEY<br/><span> Features</span></h2>
                                <div className="horizonral-subtitle"><span>FEATURES</span></div>
                                
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                   
                    <section data-scrollax-parent="true" id="solutions">
       
            <div className="container">
                <div className="section-title fl-wrap">
               
                    <h2>PLATFORM <span> FEATURES</span></h2>
                    
                </div>
              
                <div class="features-box-container fl-wrap"><div class="row"><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-gear-icon"></i></div><h3>100% Customizable</h3><p>Tailored to your exact needs</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-web-icon"></i></div><h3>100% Web Browser Based</h3><p>No client end software is required</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fab pragicts-responsive-icon"></i></div><h3>100% Responsive</h3><p>Run it on any Internet-enabled device.</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-open-icon"></i></div><h3>100% Open Source</h3><p>Deploy it on any platform/configuration</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fab pragicts-live-icon"></i></div><h3>Live Dashboard</h3><p>For real-time tracking and corrective action</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-report-icon"></i></div><h3>100% Customizable Reports</h3><p>Reports to meet your requirements</p></div></div></div>         
            </div>
            <div className="sec-lines"></div>
        </section>
        <section id="stats" className="dark-bg"  data-scrollax-parent="true">
            <div className="bg home-sec-bg par-elem"  data-scrollax="properties: { translateY: '30%' }"></div>
    
            <div className="container">
                <div className="section-title">
                    <h2 style={{paddingBottom:'0px !important'}}>APPLICATION   <span>FEATURES </span></h2>

                    <div className="horizonral-subtitle"><span>FEATURES</span></div>
                </div>
                <div class="features-box-container fl-wrap"><div class="row"><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-app-icon"></i></div><h3>LIVE DASHBOARD</h3><p>Tracking your customer sentiments across your entire organization down to the specific feedback device.</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-app-icon"></i></div><h3>DEFINITIONS MANAGEMENT</h3><p>Define your smiley devices, locations, and smiley emoji as per your needs.</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fab pragicts-app-icon"></i></div><h3>SENTIMENT NOTIFICATIONS</h3><p>Configure for notifications on emoji severity for immediate actioning.</p></div></div><div class='row'><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-app-icon"></i></div><h3>COMPREHENSIVE REPORTS</h3><p>Bespoke reports to meet your exact needs with export options.</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fab pragicts-app-icon"></i></div><h3>APPLICATION INTEGRATION</h3><p>Smiley architecture enables integration with any 3rd party application.</p></div><div class="features-box col-md-4"><div class="time-line-icon"><i class="fal pragicts-app-icon"></i></div><h3>ACCESS MANAGEMENT</h3><p>Smiley platform access is fully managed through user-role access.</p></div></div></div>
            </div>
        </section>
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage
